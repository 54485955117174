/*import axios from 'axios'
//import store from '../store/store'

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL, // projenin ana dizininde .env.production ve .env.development dosyalarında VUE_APP_API_URL tanımlanmış olmalıdır.
  timeout: 20 * 1000,
  //headers: { 'Authorization': store.getters.isAuthenticated }
  // headers: {'Authorization':'headers'}

})*/


import store from '../../core/services/store/auth.module'
//import axios from '../core/services/api.service.js'
import JwtService from "@/core/services/jwt.service";
//import jwtService from "../jwt.service";
//import axios from "axios";
import Vue from "vue";
export default {
  ifAuthenticated(to, from, next) {
    let isUserType = to.meta.userType.indexOf(store.state.user.role)
   
    if (store.getters.isAuthenticated && isUserType >= 0) {
      // axios.defaults.headers = { 'Authorization': store.state.token }
      Vue.axios.defaults.headers[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`
      next()
    } else {
      if(isUserType < 0){
        //3 yetkininde girebildiği sayfa olmalı
        next('/login')
      }else{
        next('/login')
      }
    }
  },

  ifNotAuthenticated(to, from, next) {
    if (!store.getters.isAuthenticated) {
      //axios.defaults.headers = { 'Authorization': '' }
      Vue.axios.defaults.headers[
        "Authorization"
      ] = ``
      next()
      return
    }
    next('/workslist')
  },
}

