import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import jwtService from "../jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "/user/new";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: jwtService.getUser(),
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getUserinfo(state){
    return state.user;
  },
  isGlobalAdmin(state){
    //console.log("user rolü",state.user.role);
    return state.user.role == 'GlobalAdmin'? true : false
  },

  isCro(state){
    return state.user.role == 'CRO' ? true : false
  },

  isNormalUser(state){
    return state.user.role == 'User' ? true : false
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve,reject)=> {
      //console.log('geldi', credentials)
      let body = {
        username : credentials.email,
        password : credentials.password
      }
      ApiService.post("login", body)
        .then(({ data }) => {
         // console.log('içerde: ', data)
          context.commit(SET_AUTH, data);
          ApiService.setHeader();
          resolve(data);
        })
        .catch(({ response }) => {
          //console.log('errr : ', response)
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
     // console.log("yeni kayıt girdi",credentials);
      ApiService.post("/user/new", credentials)
        .then(({ data }) => {
          //console.log("yeni kayıt içerde")
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    //console.log("token",jwtService.getToken());
    if (JwtService.getToken()) {
      ApiService.setHeader();
      
      // ApiService.get("verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });
    } 
    else {
      //console.log("purge auth girdi");
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    //console.log("set auth",data);
    state.isAuthenticated = true;
    state.user = data;
    state.errors = {};
    JwtService.saveToken(state.user.accessToken);
    JwtService.saveUser(data);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    jwtService.destroyUser();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
