const ID_TOKEN_KEY = "id_token";
const USER_DATA = "user_data";


export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
 // console.log("token",token);
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getUser = () => {
 // console.log("test",window.localStorage.getItem(USER_DATA));
   let data = JSON.parse(window.localStorage.getItem(USER_DATA))
   return data;
};

export const saveUser = data => {
  //console.log("token22",data);
  let item = JSON.stringify(data);
  
  window.localStorage.setItem(USER_DATA, item);
};

export const destroyUser = () => {
  window.localStorage.removeItem(USER_DATA);
};


export default { getToken, saveToken, destroyToken,getUser,saveUser,destroyUser };


