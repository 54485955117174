// action types
export const APPEND_SELECTEDPAGEITEMNAME = "appendSelectedPageItemName";

// mutation types
export const SET_SELECTEDPAGEITEMNAME = "setSelectedPageItemName"; 
export const REMOVE_SELECTEDPAGEITEMNAME ="removeSelectedPageItemName";
export const SET_FROMWORKLIST ="setFromWorkList";
export const REMOVE_FROMWORKLIST ="removeFromWorkList";

export default {
  state: {
   selectedPageItemName:'',
   fromWorkList:false
  },
  getters: {
    selectedPageItemName(state) {
      return state.selectedPageItemName;
    },
  },
  actions: {
    /** 
     * @param state
     * @param selectedPageItemName
     */
    [SET_SELECTEDPAGEITEMNAME](state, selectedPageItemName) { 
      state.commit(SET_SELECTEDPAGEITEMNAME, selectedPageItemName);
    }, 
    [REMOVE_SELECTEDPAGEITEMNAME](state) { 
      state.commit(REMOVE_SELECTEDPAGEITEMNAME);
    }, 
    [SET_FROMWORKLIST](state, fromWorkList) { 
      state.commit(SET_FROMWORKLIST, fromWorkList);
    }, 
    [REMOVE_FROMWORKLIST](state) { 
      state.commit(REMOVE_FROMWORKLIST,);
    }, 
  },
  mutations: {
    
    [SET_SELECTEDPAGEITEMNAME](state, payload) {
      state.selectedPageItemName = payload;
    },
    [REMOVE_SELECTEDPAGEITEMNAME](state) {
      state.selectedPageItemName = "";
    },
    [SET_FROMWORKLIST](state,fromWorkList)
    {
      state.fromWorkList=fromWorkList;
    },
    [REMOVE_FROMWORKLIST](state)
    {
      state.fromWorkList=false;
    }
  }
};
