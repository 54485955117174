<template>
  <!-- <div id="inspire" v-on:mousedown="updateCoordinates" v-bind="callService">
    <router-view> </router-view>
  </div> -->
  <v-app>
    <v-dialog v-model="dialog" max-width="750px" height="750px" persistent>
      <v-card class="elevation-5">
        <v-row style="align-items: center; max-height: 90px !important" class="px-5">
          <v-col cols="11">
            <v-card-title style="font-size: 25px !important">
              Sistem Uyarısı</v-card-title>
          </v-col>
        </v-row>
        <!-- <v-card-title> Giriş</v-card-title> -->
        <div class="page-dialog">
          <v-card-text>
            <v-row>
              <v-col>
                <span style="font-size: 12px !important">
                  *20 dakikadır bir işlem gerçekleştirmediniz.Devam etmek için
                  lütfen devam et butonuna basınız.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col md="4 ml-auto">
                <button type="button" class="btn btn-success" @click="refreshToken()">
                  Devam Et
                </button>
              </v-col>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-main>
      <v-fade-transition mode="out-in">
        <div id="inspire">
          <router-view />
        </div>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import "@/global.scss";
// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/

// .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {

//     font-weight: 800;
//     font-size: 13px;
// }
// .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {

//    font-size: 16px;
// }
// .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
//     font-size: 14px;
//     /* height: 48px; */
// }
// .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {

//     height: 48px;
//     font-size: 16px;
// }

// .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
// .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
// .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
//    font-size: 13px !important;
// }
// .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
// .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
// .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
//    font-size: 16px !important;
// }
// .theme--light.v-input {

//     margin-left: 17px;
// }
// .theme--light.v-data-table {
//     margin-top: 30px;
// }
// .v-application--is-ltr .v-text-field .v-label {
//     font-size: 15px;
// }
// .aside-menu .menu-nav > .menu-item > .menu-heading .menu-text, .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
//     font-size: 17px;
// }
// .aside-menu .menu-nav > .menu-section .menu-text {
//     font-size: 15px;
// }
// .aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon, .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {

//     font-size: 25px;
// }
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { LOGOUT } from "@/core/services/store/auth.module";

import ApiService from "@/core/services/api.service";
import jwtService from "../src/core/services/jwt.service";
import router from "./router";
import { log } from "console";

export default {
  data: () => ({
    dialog: false,
    clc: false,
    x: 0,
    y: 0,
    beforeX: 0,
    beforeY: 0,
    control: false,
    z: null,
    t: null
  }),
  name: "MetronicVue",

  mounted() {
    var timer;

    this.updateCoordinates();

    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },

  
  methods: {
  
    async updateCoordinates(event) {
      window.addEventListener("mousemove", () => {
        // console.log("-*-* " + router.history.current?.name); 
        if (router.history.current?.name != "login" && this.dialog != true) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            console.log("Mouse stopped22");
            this.bir();
            this.dialog = true;
          }, 1200000);
        }
      });
    },

    async getLogout() {
      this.z = setTimeout(() => {
        this.bir();
        this.dialog = true;
      }, 300000);
    },

    async bir() {
      console.log('bir');
      await this.iki();
    },

    async iki() {
      console.log('iki çalıştı')
      this.t = setTimeout(() => {
        if (!this.clc) this.logout();
      }, 300000);
    },

    async servis() {
      console.log('servis çalıştı')
      ApiService.post("refreshtoken")
        .then(({ data }) => {
          clearTimeout(this.z);
          clearTimeout(this.t);
          console.log("refresh token geldi", data);
          jwtService.saveToken(data.accessToken);
        })
        .catch(() => {
          console.log("error", err);
        });
    },

    async logout() {
      console.log('logout servis')
      this.dialog = false;
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }).catch(() => { }));
    },

    async refreshToken() {

      clearTimeout(this.z);
      clearTimeout(this.t);

      this.dialog = false;
      await this.servis();
    },
  },
};
</script>
